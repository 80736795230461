@import '../../styles/helpers/index.scss';

.steps {
  &__list {
    margin: 75px 0 0;
    @include grid($columns: 3, $column-gap: 30px);

    & > div {
      position: relative;
      padding: 80px 40px;
      background-color: map-get($colors, 'green');
      color: #fff;
      border-radius: 30px;

      @media screen and (max-width: $tablet-md) {
        padding: 30px 0;
      }

      .number {
        @include absolute(20px, auto, 20px);
        @include square(60px);

        border: 1px solid #fff;
        border-radius: 50%;

        line-height: 60px;
        text-align: center;

        font-family: 'Bebas Neue';
        font-size: 40px;
        font-weight: 700;

        @media screen and (max-width: $tablet-md) {
          position: relative;
          left: 50%;
          right: auto;
          transform: translateX(-50%);
        }
      }

      .icon {
        height: 60px;

        @media screen and (max-width: $tablet-md) {
          text-align: center;
          margin: 60px 0 0;
        }

        img {
          height: inherit;
          width: auto;
          object-fit: cover;
        }
      }

      .text {
        margin: 40px 0 0;
        font-family: 'Bebas Neue';
        font-size: 40px;
        line-height: 100%;

        @media screen and (max-width: $tablet-md) {
          text-align: center;
        }
      }
    }
  }
}
