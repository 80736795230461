@import '../../styles/helpers/index.scss';

.cta {
  background-color: map-get($colors, 'green');
  &__inner {
    padding: 100px 0 0;
    @include space-between;
    align-items: center;

    @media screen and (max-width: $tablet-md) {
      padding: 60px 0;
      text-align: center;
    }

    .image {
      @media screen and (max-width: $tablet-md) {
        display: none;
      }
    }

    .section-title {
      color: #fff;
      font-weight: normal;
    }

    .btn {
      display: inline-block;
      margin: 20px 0;
    }

    .phone {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: 200%;

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
