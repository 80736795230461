@import '../../styles/helpers/index.scss';

.brands {
  &__inner {
    padding: 120px 80px 80px;
    background-color: #fff;
    border-radius: 30px;

    @media screen and (max-width: $tablet-md) {
      padding: 60px 30px;
    }
  }

  &__list {
    padding: 15px 0;

    div {
      @include align-center;

      img {
        height: auto;
        width: 75%;
        margin: 0 auto;
        object-fit: contain;

        @media screen and (max-width: $tablet-sm) {
          width: 60%;
        }
      }
    }
  }

  &__array {
    padding: 0 0 0 40px;
    columns: 3;

    @media screen and (max-width: $tablet-sm) {
      columns: 2;
      padding: 0 15px 0;
    }

    li {
      position: relative;
      font-size: 18px;
      line-height: 200%;
      color: map-get($colors, 'text');
      text-indent: 30px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 12px;
      }

      &::before {
        @include pseudo-size(10px, 10px);
        @include absolute-centering;

        background-color: map-get($colors, 'green');
        border-radius: 50%;
      }
    }
  }
}
