@import '../../styles/helpers/index.scss';

.thanks {
  height: 100vh;

  &__inner {
    height: inherit;

    @include align-center;
    flex-direction: column;
    justify-content: center;

    & > p {
      &:not(:last-of-type) {
        margin: 0 0 15px;
      }
    }

    .btn {
      margin: 30px 0 0;
    }
  }
}
