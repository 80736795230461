@import '../../styles/helpers/index.scss';

.call-us {
  @include background-image('../../static/call-us-bg.webp');
  &__inner {
    display: flex;
    flex-direction: column;
    padding: 100px 0 30px;
    color: #fff;

    @media screen and (max-width: $tablet-md) {
      padding: 60px 0 30px;
    }

    .title {
      @include space-between;

      @media screen and (max-width: $tablet-md) {
        flex-direction: column;
      }

      .section-title {
        color: #fff;
      }

      p {
        @include max-width(460px);

        font-size: 18px;
        line-height: 150%;

        @media screen and (max-width: $tablet-md) {
          margin: 15px 0 0;
        }
      }
    }

    .links {
      margin: 50px 0 0;
      @include align-center;

      @media screen and (max-width: $tablet-md) {
        justify-content: space-between;
      }

      .btn {
        margin: 0 30px 0 0;

        @media screen and (max-width: $tablet-md) {
          margin: 0;
        }
      }

      span {
        font-size: 24px;
        font-weight: 700;
        line-height: 150%;
        user-select: none;
      }
    }

    .payments {
      margin: 45px 0 0;
      @include align-center;
      align-self: flex-end;

      @media screen and (max-width: $tablet-md) {
        justify-content: space-between;
        align-self: auto;
      }

      & > * {
        @include scale;
        object-fit: cover;
        height: 50px;

        &:not(:last-child) {
          margin: 0 20px 0 0;

          @media screen and (max-width: $tablet-md) {
            margin: 0;
          }
        }
      }
    }
  }
}
