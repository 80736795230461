@import '../../styles/helpers/index.scss';

.hero {
  margin: 80px 0 0;

  @media screen and (max-width: $tablet-md) {
    margin: 30px 0 0;
  }

  &__inner {
    @include space-between;

    @media screen and (max-width: $tablet-md) {
      flex-direction: column;
      text-align: center;
    }

    h1 {
      color: map-get($colors, 'heading');
      font-weight: normal;
      font-size: 100px;
      line-height: 120%;

      @media screen and (max-width: $tablet-md) {
        font-size: calc(100px * 0.5);
      }
    }

    p {
      @include max-width(370px);
      margin: 30px 0 80px;
      font-size: 30px;
      font-weight: bold;
      line-height: 150%;

      @media screen and (max-width: $tablet-md) {
        margin: 30px 0;
        font-size: 24px;
        text-align: center;
      }
    }

    .links {
      @include align-center;

      @media screen and (max-width: $tablet-md) {
        flex-direction: column;
      }

      .btn {
        margin: 0 15px 0 0;

        @media screen and (max-width: $tablet-md) {
          margin: 0 0 15px;
        }
      }

      span {
        font-size: 18px;
        font-weight: 600;
        line-height: 200%;
        user-select: none;

        a {
          color: inherit;
          text-decoration: none;

          @media screen and (max-width: $tablet-md) {
            text-decoration: underline;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .image {
      @include max-width(640px);

      @media screen and (max-width: $tablet-md) {
        margin: 15px 0 0;
      }
    }
  }
}
