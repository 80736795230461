@use 'scss-reset/reset';

@import 'slick-carousel/slick/slick';
@import 'slick-carousel/slick/slick-theme';

@import './helpers/variables.scss';
@import './helpers/mixins.scss';
@import './typography.scss';

*,
*:before,
*:after {
  box-sizing: inherit;
}

// *::-webkit-scrollbar {
//   display: none;
// }

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: map-get($colors, 'background');
  font-size: 18px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Inter', sans-serif;
  color: map-get($colors, 'text');

  overflow: auto;
  overflow-x: hidden;

  @media screen and (max-width: $tablet-md) {
    font-size: 16px;
  }

  #root {
    height: inherit;

    display: flex;
    flex-direction: column;

    main {
      flex: 1 0 auto;
      padding-top: 120px;

      transition: all 0.3s ease;

      @media screen and (max-width: $tablet-md) {
        padding-top: 137px;
      }
    }
  }
}

section {
  &:not(:first-of-type) {
    margin: 120px 0 0;

    @media screen and (max-width: $tablet-md) {
      margin: 60px 0 0;
    }
  }
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.btn {
  cursor: pointer;
  padding: 12px 40px;
  border-radius: 50px;

  text-decoration: none;
  text-transform: uppercase;

  font-size: 18px;
  font-weight: 700;
  line-height: 24px;

  @media screen and (max-width: $tablet-md) {
    font-size: 18px;
    padding: 8px 20px;
  }

  &.green {
    @include hover-invert(map-get($colors, 'green'), #fff);
  }

  &.dark {
    @include hover-invert(map-get($colors, 'heading'), #fff);
  }
}
