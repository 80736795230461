@import '../../styles/helpers/index.scss';

.reviews {
  &__list {
    margin: 30px 0 0;
    padding: 0 0 30px;

    &--card {
      background-color: #fff;
      border-radius: 30px;
      padding: 45px 40px 30px;
      margin: 0 20px 0 0;

      @media screen and (max-width: $tablet-md) {
        margin: 0;
      }

      .icon {
        margin: 0 0 15px;
        width: 35px;
        height: 30px;

        @include background-image('../../static/icon-quote.svg', contain);
      }

      & > p {
        font-size: 18px;
        line-height: 150%;
        height: 200px;
        overflow: scroll;
      }

      .meta {
        @include space-between;
        align-items: center;

        .logo {
          @include square(40px);
          background-color: map-get($colors, 'green');
          border-radius: 50%;
          color: #fff;
          font-size: 24px;
          line-height: 40px;
          text-align: center;
        }

        .customer {
          .name {
            font-size: 18px;
            font-weight: 700;
            line-height: 120%;
          }

          .date {
            margin: 5px 0 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 120%;
          }
        }

        .stars {
          align-self: flex-end;
          color: map-get($colors, 'green');
          font-size: 16px;

          span {
            margin: 0 0 0 5px;
            color: #000;
            font-size: 12px;
          }
        }
      }
    }
  }
}
