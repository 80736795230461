@import '../../styles/helpers/index.scss';

.about {
  &__content {
    margin: 30px 0 0;
    @include grid;

    .text {
      & > * {
        &:not(:last-child) {
          margin: 0 0 15px;
        }
      }
    }

    .image {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 8px 24px 0 rgba(34, 48, 114, 0.07);

      img {
        height: inherit;
        width: 100%;
        object-fit: cover;
        user-select: none;
      }
    }

    &--static {
      margin: 65px 0 0;
      @include grid;

      .image {
        @include grid;

        @media screen and (max-width: $tablet-md) {
          display: none;
        }

        & > div {
          display: flex;
          justify-content: center;
          flex-direction: column;
          row-gap: 30px;

          img {
            border-radius: 10px;
          }
        }
      }

      .text {
        h2,
        h3 {
          color: map-get($colors, 'heading');
          margin: 0 0 20px !important;
        }

        strong {
          font-weight: bold;
        }

        & > * {
          &:not(:last-child) {
            margin: 0 0 15px;
          }
        }
      }
    }
  }
}
