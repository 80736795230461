@import '../../styles/helpers/index.scss';

.services {
  &__inner {
    margin: 60px 0 0;
    @include grid($columns: 3, $row-gap: 30px, $column-gap: 30px);

    @media screen and (max-width: $tablet-md) {
      margin: 30px 0 0;
    }

    & > * {
      &:not(:last-child) {
        @include scale;
        position: relative;
        background-color: #fff;
        border-radius: 30px;
        padding: 70px 40px 50px;
        text-decoration: none;

        @media screen and (max-width: $tablet-md) {
          padding: 40px;
        }

        &::before {
          @include pseudo-size(60px, 60px);
          @include absolute(20px, auto, 20px);
          @include background-image('../../static/icon-three-dots.svg', cover);
        }

        .icon {
          height: 100px;

          img {
            height: inherit;
            width: auto;
            object-fit: cover;
          }
        }

        .title {
          margin: 40px 0 0;
          color: map-get($colors, 'heading');
          font-family: 'Bebas Neue';
          font-size: 40px;
          line-height: 100%;
        }
      }

      &:last-child {
        grid-area: 1 / 3 / 3 / 4;

        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;

        img {
          max-width: 320px;
          margin: 0 0 -30px;
          vertical-align: top;
        }

        @media screen and (max-width: $tablet-md) {
          display: none;
        }
      }
    }
  }
}
