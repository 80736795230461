@import '../../styles/helpers/index.scss';

.page-header {
  position: fixed;
  width: 100%;
  top: 20px;
  left: 0;
  z-index: 100;
  transition: all 0.3s ease;

  @media screen and (max-width: $tablet-md) {
    top: 0;
    background-color: #fff;
  }

  &.shadow {
    box-shadow: 0 5px 15px 0 rgba(#000, 0.5);
    top: 0;
    background-color: #fff;
  }

  &__inner {
    @include space-between;
    align-items: center;

    background-color: #fff;
    padding: 10px 20px;
    border-radius: 50px;

    @media screen and (max-width: $tablet-md) {
      border-radius: 0;

      flex-direction: column;
    }

    .logo {
      display: inline-block;
      height: 80px;

      @media screen and (max-width: $tablet-md) {
        height: 60px;
      }

      img {
        height: inherit;
        width: 100%;
      }
    }

    nav {
      @include align-center;

      @media screen and (max-width: $tablet-md) {
        margin: 15px 0 0;
      }

      .phone {
        position: relative;
        font-family: 'Bebas Neue';
        font-size: 30px;
        line-height: 24px;
        text-indent: 45px;
        text-decoration: none;

        color: map-get($colors, 'heading');

        @media screen and (max-width: $tablet-md) {
          font-size: 24px;
          text-indent: 30px;
        }

        &::before {
          @include pseudo-size(30px, 30px);
          @include absolute-centering;
          @include background-image('../../static/icon-phone.svg');

          @media screen and (max-width: $tablet-md) {
            height: 20px;
            width: 20px;
          }
        }
      }

      .btn {
        margin: 0 0 0 50px;

        @media screen and (max-width: $tablet-md) {
          margin: 0 0 0 30px;
        }
      }
    }
  }
}
