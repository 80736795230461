@import '../../styles/helpers/index.scss';

.home {
  height: 100vh;

  &__inner {
    height: inherit;

    @include align-center;
    flex-direction: column;
    justify-content: center;

    p {
      margin: 45px 0 30px;
      font-family: 'Bebas Neue';
      font-size: 36px;
      text-align: center;
      text-transform: uppercase;
    }

    ul {
      li {
        position: relative;
        text-indent: 20px;

        &::before {
          @include pseudo-size(10px, 10px);
          @include absolute-centering;

          background-color: map-get($colors, 'green');
          border-radius: 50%;
        }

        a {
          text-decoration: none;
          font-size: 22px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
