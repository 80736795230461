@import '../../styles/helpers/index.scss';

.get-quote {
  &__inner {
    padding: 115px 40px;
    background-color: #fff;
    border-radius: 30px;

    @media screen and (max-width: $tablet-md) {
      padding: 60px 30px;
    }

    & > p {
      margin: 5px 0 0;
      font-size: 18px;
      line-height: 150%;
    }

    form {
      margin: 60px 0 0;
      @include grid($columns: 2);

      @media screen and (max-width: $tablet-md) {
        row-gap: 20px;
      }

      fieldset {
        & > * {
          width: 100%;

          &:not(:last-child) {
            margin: 0 0 20px;
          }
        }

        input,
        select {
          height: 50px;
          line-height: 50px;
          padding: 0 25px;
          border-radius: 10px;
          border: 1px solid #acb377;

          &:not([type='submit']) {
            &::placeholder {
              font-family: inherit;
              font-weight: inherit;
              font-size: inherit;
              line-height: inherit;
            }
          }
        }

        textarea {
          border-radius: 10px;
          border: 1px solid #acb377;
          padding: 25px;
          height: 100%;
          max-height: 190px;

          @media screen and (max-width: $tablet-md) {
            height: 150px;
          }

          &::placeholder {
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            line-height: inherit;
          }
        }

        .data-protection {
          @include align-center;
          justify-content: center;
          line-height: 100%;
          color: map-get($colors, 'text');
        }
      }
    }
  }
}
