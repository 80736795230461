@import '../../styles/helpers/index.scss';

.why-us {
  padding: 0 0 300px;

  @media screen and (max-width: $tablet-md) {
    padding: 0;
  }

  &__inner {
    position: relative;
    background-color: #fff;
    padding: 120px 40px 230px;
    border-radius: 30px;

    @media screen and (max-width: $tablet-md) {
      padding: 0;
      background-color: transparent;
    }
  }

  .counter {
    margin: 15px 0 0;
    @include grid($columns: 3);

    @media screen and (max-width: $tablet-md) {
      row-gap: 15px;
    }

    &__item {
      @include align-center;
      justify-content: center;

      @media screen and (max-width: $tablet-md) {
        background-color: #fff;
        padding: 12px 0;

        text-align: center;
        border-radius: 30px;
      }
    }

    .number {
      font-family: 'Bebas Neue';
      color: map-get($colors, 'green');
      font-size: 120px;
      line-height: 120%;

      @media screen and (max-width: $tablet-md) {
        font-size: 60px;
      }
    }

    .title {
      margin: 0 0 0 20px;
      font-size: 24px;
      font-weight: 700;
      line-height: 150%;

      @media screen and (max-width: $tablet-md) {
        font-size: 18px;
      }
    }
  }

  .reasons {
    position: absolute;
    left: 40px;
    right: 40px;
    bottom: -220px;

    @include grid($columns: 3);

    @media screen and (max-width: $tablet-md) {
      margin: 30px 0 0;
      position: relative;
      bottom: unset;
      left: unset;
      right: unset;
      row-gap: 15px;
    }

    &__item {
      @include scale;
      padding: 50px 40px;
      background-color: map-get($colors, 'green');
      color: #fff;
      border-radius: 30px;

      @media screen and (max-width: $tablet-md) {
        padding: 30px;
      }

      .icon {
        @include square(60px);

        img {
          height: inherit;
          object-fit: cover;
          user-select: none;
        }
      }

      .title {
        margin: 30px 0;
        font-family: 'Bebas Neue';
        font-size: 30px;
        line-height: 120%;

        @media screen and (max-width: $tablet-md) {
          margin: 15px 0;
        }
      }

      .text {
        font-size: 18px;
        line-height: 150%;

        @media screen and (max-width: $tablet-md) {
          font-size: 16px;
        }
      }
    }
  }
}
