@import '../../styles/helpers/index.scss';

.page-footer {
  padding: 100px 0 30px;
  background-color: map-get($colors, 'green');

  @media screen and (max-width: $tablet-md) {
    padding: 60px 0 80px;
  }

  &__inner {
    @include align-center;
    flex-direction: column;
  }

  .logo {
    @include scale;
    display: inline-block;
    height: 90px;

    img {
      height: inherit;
      width: 100%;
    }
  }

  .phone {
    margin: 70px 0 30px;

    @media screen and (max-width: $tablet-md) {
      margin: 30px 0 15px;
    }
  }

  .phone,
  .email {
    position: relative;
    font-family: 'Bebas Neue';
    font-size: 80px;

    color: #fff;
    text-decoration: none;

    @media screen and (max-width: $tablet-md) {
      font-size: 2em;
    }

    &::before {
      @include pseudo-size(0, 2px);
      @include absolute(80%, 0);
      background-color: #fff;

      transition: width 0.3s ease;
    }

    &:hover {
      &::before {
        width: 100%;
      }
    }
  }

  .underline {
    margin: 90px 0 0;
    @include space-between;
    width: 100%;
    color: #fff;

    font-size: 12px;

    @media screen and (max-width: $tablet-md) {
      margin: 30px 0 0;
      flex-direction: column;
      text-align: center;
    }

    a {
      color: #fff;
      text-decoration: none;

      @media screen and (max-width: $tablet-md) {
        margin: 15px 0 0;
      }

      strong {
        font-weight: bold;
      }

      &:hover {
        strong {
          text-decoration: underline;
        }
      }
    }
  }
}
