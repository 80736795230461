@import '../../styles/helpers/index.scss';

.areas {
  &__inner {
    margin: 40px 0 0;
    @include space-between;

    @media screen and (max-width: $tablet-md) {
      margin: 30px 0 0;
      flex-direction: column;
    }

    & > div {
      @include max-width(615px);

      @media screen and (max-width: $tablet-md) {
        display: none;
      }
    }

    ul {
      @include columns;

      @media screen and (max-width: $tablet-md) {
        columns: 1;
      }

      li {
        position: relative;
        text-indent: 30px;

        &::before {
          @include pseudo-size(10px, 10px);
          @include absolute-centering;

          background-color: map-get($colors, 'green');
          border-radius: 50%;
        }
      }
    }
  }
}
